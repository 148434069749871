import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "電子レセプトについて調べた話",
  "date": "2018-07-06T02:40:00.000Z",
  "slug": "entry/2018/07/06/114000",
  "tags": ["medley"],
  "hero": "./2018_07_06.png",
  "heroAlt": "電子レセプト"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の竹内です。最近子どものプリンセスへの強い憧れに若干引いております。`}</p>
    <p>{`さて先日、TechLunch という社内勉強会で「電子レセプト」について話しましたので、こちらでも簡単に紹介させていただきます。`}</p>
    <h1>{`レセプトとは`}</h1>
    <p>{`ところで、みなさまは「レセプト」についてご存知でしょうか？私はメドレーに入社するまで知りませんでした。`}</p>
    <p>{`レセプトとは医療機関が支払基金へ診療報酬を請求するための明細書情報のことです。`}</p>
    <p>{`と言っても、初めて聞かれる方もいらっしゃると思いますので、医療機関におけるお金の流れとともに簡単に説明します。`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.ssk.or.jp/kikin.images/kikin_image01.png",
        "alt": "https://www.ssk.or.jp/kikin.images/kikin_image01.png"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.ssk.or.jp/kikin.html"
      }}>{`（支払基金ってどんなところ？｜社会保険診療報酬支払基金より）`}</a></p>
    <p>{`医療機関は「診療」の対価として、被保険者等（≒ 患者）からお金を受け取るわけですが、被保険者の加入する保険や公費によってその額は変わります。負担割合が 3 割の場合、残りの 7 割を被保険者が加入する保険組合などへ請求する必要があります。`}</p>
    <p>{`この保険組合などへの請求を取りまとめ、内容を審査しているのが支払基金と呼ばれる組織で、医療機関は月に一度、前月の患者ごとの診療点数を計算し「レセプト」として支払基金に提出することになります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.ssk.or.jp/kikin.images/kikin_image03.png",
        "alt": "https://www.ssk.or.jp/kikin.images/kikin_image03.png"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.ssk.or.jp/kikin.html"
      }}>{`（支払基金ってどんなところ？｜社会保険診療報酬支払基金より）`}</a></p>
    <p>{`レセプトには、請求する診療点数のほか、医療機関の情報、被保険者の情報（氏名などの基本情報、加入している保険者情報）、診療行為や傷病名に関する情報などが含まれています。`}</p>
    <p>{`「レセプト」には紙と電子データとありますが、現在は原則として電子レセプトを提出することが求められているそうです（`}<a parentName="p" {...{
        "href": "https://www.ssk.or.jp/seikyushiharai/rezept/iryokikan/rezept_03.html#cmsyuyo02"
      }}>{`電子レセプト請求に係る猶予措置及び免除措置について｜社会保険診療報酬支払基金`}</a>{`）。`}</p>
    <h1>{`電子レセプトとレセ電ビューア`}</h1>
    <p>{`電子レセプトについての仕様は支払基金によって公開されています。今回は「`}<a parentName="p" {...{
        "href": "https://www.ssk.or.jp/seikyushiharai/rezept/iryokikan/iryokikan_02.html"
      }}>{`電子レセプト作成の手引き`}</a>{`」という資料を元に「医科」のレセプトについて調べて発表しました。`}</p>
    <p>{`発表資料はこちら。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/2a1e3455db2b48ada3377cf44d575ba8" title="電子レセプトの所感 ～わかりあえないことから～ " allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`電子レセプトの実体は CSV 形式のシンプルなテキストファイルです（拡張子は UKE なので、UKE ファイルと呼ぶこともあるようです）。ただ、電子レセプトの仕様を把握したとしても、やはり CSV ファイルを見て内容を把握するのは至難の業です。ファイル上では診療行為や医薬品、傷病名はコードとして表現されているため、`}<a parentName="p" {...{
        "href": "https://www.ssk.or.jp/seikyushiharai/tensuhyo/kihonmasta/index.html"
      }}>{`マスタデータ`}</a>{`を参照しなければその内容まで理解することはできないからです。`}</p>
    <p>{`そこで登場するのが、レセ電ビューアというツールで、ORCA Project によって公開されているフリーの電子レセプトビューアです。「レセ電＝電子レセプト」ですね。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.orca.med.or.jp/receipt/use/jma-receview.html"
      }}>{`https://www.orca.med.or.jp/receipt/use/jma-receview.html`}</a></p>
    <p>{`（※上記ページでは「レセ電ビューア」と「レセ電ビューワ」が混在していますが、本ブログでは「レセ電ビューア」で統一しています）`}</p>
    <p>{`レセ電ビューアは Windows と Ubuntu で動作し、上述した UKE ファイルを読み込み、見やすく表示してくれる便利ツールです。ここからはレセ電ビューアをインストールし、電子レセプトを読み込んで表示するところまでを紹介したいと思います。`}</p>
    <h2>{`レセ電ビューアのインストール`}</h2>
    <p>{`レセ電ビューアは Windows と Ubuntu 上で動作しますので、まずは Ubuntu 環境を準備します。私は VirtualBox 上に Ubuntu 環境を用意しました。
公式のインストールマニュアル（`}<a parentName="p" {...{
        "href": "https://ftp.orca.med.or.jp/pub/receview/manual/jma-receview-install-linux.pdf"
      }}>{`ubuntu 環境へのレセ電ビューアインストール`}</a>{`）に基づいて作業していきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Keyring と apt-line の追加`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` -q https://ftp.orca.med.or.jp/pub/ubuntu/archive.key
$ apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` archive.key
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` -q -O `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
/etc/apt/sources.list.d/jma-receipt-xenial50.list `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
https://ftp.orca.med.or.jp/pub/ubuntu/jma-receipt-xenial50.list
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` dist-upgrade

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# レセ電ビューアパッケージインストール`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` jma-receview
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` jma-receview-server

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# レセ電ビューア起動`}</span>{`
$ jma-receview`}</code></pre></div>
    <h2>{`レセ電ビューアの設定`}</h2>
    <p>{`電子レセプトに含まれる診療行為などのコードに対応するマスタデータを参照するため、日レセ（jma-receipt）の DB を利用することができます。今回は DBFile 形式で DB に接続します。レセ電ビューアに付属するスクリプトを実行し、jma-receipt の DB から必要なテーブルをダンプすることができます。このファイルをレセ電ビューアに設定することで、電子レセプトの表示がよりわかりやすくなります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# レセ電ビューアで使う DBFile を作る`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# https://ftp.orca.med.or.jp/pub/receview/manual/jma-receview.pdf`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 「2.7.4 DBFile の作成方法」`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span>{` orca
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /usr/share/jma-receview/db/
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /var/tmp/dbfile
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /usr/share/jma-receview/db/make_dbfile.sh /var/tmp/dbfile/
$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /var/tmp/dbfile/
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span>{` ./make_dbfile.sh `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20170101`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -lh
合計 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`.9M
-rwxr-xr-x `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`.9K  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:55 make_dbfile.sh
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`.2M  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_byomei.rdb
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`89`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_dbkanri.rdb
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca 330K  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_hknjainf.rdb
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{`.6K  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_labor_sio.rdb
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca 343K  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_syskanri.rdb
-rw-r--r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` orca orca `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`.1M  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` 月 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:57 tbl_tensu.rdb`}</code></pre></div>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180706/20180706112205.png",
      "alt": "20180706112205.png"
    }}></img>
    <p>{`接続設定で「DBFile」を選択し、先ほど作成した DBFile を選択します。`}</p>
    <h2>{`レセ電ビューア近影`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180706/20180706112221.png",
      "alt": "20180706112221.png"
    }}></img>
    <p>{`「ファイル」から UKE ファイルを開くと、レセプトに基づいて患者基本情報、保険・公費情報、診療行為情報のほか、紙レセプトのプレビューや患者単位での電子レセプトを表示することができます。また、「編集モード」に切り替えることで患者情報や病名の編集が可能で、編集した内容でレセプトを再出力することもできるようです。`}</p>
    <h1>{`おまけ`}</h1>
    <p>{`ここまで紹介してきたレセ電ビューアですが、調べてみるとどうやら Ruby で実装されているようです。これらのコードを読んでいくことで新たな地平を開くことができるかもしれません。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ dpkg -L jma-receview `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` ruby
/usr/lib/ruby
/usr/lib/ruby/2.3.0
/usr/lib/ruby/2.3.0/jma
/usr/lib/ruby/2.3.0/jma/receview
/usr/lib/ruby/2.3.0/jma/receview/menu.rb
/usr/lib/ruby/2.3.0/jma/receview/intconv.rb
/usr/lib/ruby/2.3.0/jma/receview/base.rb
/usr/lib/ruby/2.3.0/jma/receview/dbfile_lib.rb
/usr/lib/ruby/2.3.0/jma/receview/gui.rb
/usr/lib/ruby/2.3.0/jma/receview/yearconv.rb
/usr/lib/ruby/2.3.0/jma/receview/exception.rb
/usr/lib/ruby/2.3.0/jma/receview/api.rb
/usr/lib/ruby/2.3.0/jma/receview/config.rb
/usr/lib/ruby/2.3.0/jma/receview/image.rb
/usr/lib/ruby/2.3.0/jma/receview/dialog.rb
/usr/lib/ruby/2.3.0/jma/receview/upstart.rb
/usr/lib/ruby/2.3.0/jma/receview/dbslib.rb
/usr/lib/ruby/2.3.0/jma/receview/thread.rb
/usr/lib/ruby/2.3.0/jma/receview/receview.rb
/usr/lib/ruby/2.3.0/jma/receview/sickname_edit.rb
/usr/lib/ruby/2.3.0/jma/receview/dayconv.rb
/usr/lib/ruby/2.3.0/jma/receview/isoimage.rb
/usr/lib/ruby/2.3.0/jma/receview/help.rb
/usr/lib/ruby/2.3.0/jma/receview/other_csv.rb
/usr/lib/ruby/2.3.0/jma/receview/print.rb
/usr/lib/ruby/2.3.0/jma/receview/env.rb
/usr/lib/ruby/2.3.0/jma/receview/generation.rb
/usr/lib/ruby/2.3.0/jma/receview/red2cairo.rb
/usr/lib/ruby/2.3.0/jma/receview/strconv.rb
/usr/lib/ruby/2.3.0/jma/receview/log.rb
/usr/lib/ruby/2.3.0/jma/receview/hokenconv.rb
/usr/lib/ruby/2.3.0/jma/receview/keybind.rb
/usr/lib/ruby/2.3.0/jma/receview/version.rb
/usr/lib/ruby/2.3.0/jma/receview/gtk2_fix.rb
/usr/lib/ruby/2.3.0/jma/receview/preview_widget.rb
/usr/lib/ruby/2.3.0/jma/receview/command.rb

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# /usr/bin/jma-receview も ruby で書かれてました（12000 行以上ある…）`}</span></code></pre></div>
    <h1>{`まとめ`}</h1>
    <p>{`今回は電子レセプトとレセ電ビューアについて、簡単に紹介しました。`}</p>
    <p>{`わたしたちの生活とは切り離せない「医療」に関するシステムや仕様は、意外と一般公開されているものもあり、誰でも触れることができます。ただ、動作環境が制限されていたり、インターネット界隈のエンジニアがよく目にする技術とは異なるスタックで構築されていたり、それなりにハードルがあるように感じています。`}</p>
    <p>{`これらのハードルを下げ、より多くの人が「調べてみよう」「ちょっと触ってみよう」と思うようになれば、医療に関わるシステムや仕様もよりシンプルで使いやすいものになり、ひいては各医療問題の解決・患者体験の改善につながっていくのではないかな、メドレーがつなげていきたいなと思っています。`}</p>
    <p>{`最後はいいことを言って締めたい性分なのですが、いかがだったでしょうか。`}</p>
    <p>{`ここまでお読みいただき、ありがとうございました。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーの開発にご興味ある方は、こちらからご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`7/27 に開催されるデブサミ 2018Summer に協賛させていただきます。ぜひ遊びにいらしてください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://event.shoeisha.jp/devsumi/20180727"
      }}>{`https://event.shoeisha.jp/devsumi/20180727`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      